import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '@just-ai/just-ui';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';

import { FullContainer } from './components/FullContainer';
import WithSidebar from './components/WithSidebar';
import appHistory from './appHistory';
import { getMenuItems } from './routes';

import './styles/globals.css';
import './App.scss';
import 'react-toastify/dist/ReactToastify.min.css';

const Filters = lazy(() => import('./pages/Filters'));
const UserRules = lazy(() => import('./pages/UserRules'));
const Tokens = lazy(() => import('./pages/Tokens'));
const Audit = lazy(() => import('./pages/Audit'));
const Playground = lazy(() => import('./pages/Playground'));
const Notifications = lazy(() => import('./pages/Notifications'));

registerPreloadedIcons([
  'faCircleNotch',
  'faPause',
  'faPlay',
  'farPlus',
  'faPen',
  'faJustSelect',
  'faGripVertical',
  'farPodcast',
]);

function App() {
  return (
    <Router history={appHistory}>
      <Suspense fallback={<Spinner size='4x' />}>
        <Switch>
          <Route path='/' exact>
            <Redirect to='/accessKeys' />
          </Route>
          <Route path={getMenuItems().map(el => el.link)} exact>
            <FullContainer>
              <WithSidebar>
                <Switch>
                  <Route path='/accessKeys' exact component={Tokens} />
                  <Route path='/userRules' exact component={UserRules} />
                  <Route path='/filters' exact component={Filters} />
                  <Route path='/audit' exact component={Audit} />
                  <Route path='/playground' exact component={Playground} />
                  <Route path='/notifications' exact component={Notifications} />
                </Switch>
              </WithSidebar>
            </FullContainer>
          </Route>
          <Route path='*'>
            <Redirect to='/accessKeys' />
          </Route>
        </Switch>
      </Suspense>
      <ToastContainer autoClose={2000} hideProgressBar />
    </Router>
  );
}

export default App;
