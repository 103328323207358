export const getMenuItems = () => {
  return [
    { link: '/accessKeys', title: 'accessKeys' },
    { link: '/userRules', title: 'userRules' },
    { link: '/filters', title: 'filters' },
    { link: '/audit', title: 'audit' },
    { link: '/playground', title: 'playground' },
    { link: '/notifications', title: 'notifications' },
  ];
};
